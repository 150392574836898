// eslint-disable-next-line @typescript-eslint/no-unused-vars
/* global Office console */
/* global window */

export const displayDialogue = (openUrl) => {
  var dialog;
  Office.context.ui.displayDialogAsync(openUrl, { height: 30, width: 20 }, function (asyncResult) {
    dialog = asyncResult.value;
    dialog.addEventHandler(Office.EventType.DialogMessageReceived, function (message) {
      if (message.message === "Logged in through outlook") {
        window.location.href = window.location.href + "?logged=true";
      }
    });
  });
};

export const getEmailId = () => {
  return Office.context.mailbox.item.itemId;
};

export const getEmail = () => {
  return Office.context.mailbox.userProfile.emailAddress;
};

export const sendCodeMessage = async () => {
  Office.context.ui.messageParent("Logged in through outlook");
};
