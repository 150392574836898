import React from "react";
import PropTypes from "prop-types";
import { MVCRequest } from "utility/ApiRequest";
import { displayDialogue } from "taskpane/office-document";
import { makeStyles } from "@fluentui/react-components";
import useSharedStyles from "utility/Styles";
const useStyles = makeStyles({
  innerContainer: {
    display: "flex",
    paddingTop: "60px",
    paddingRight: "16px",
    paddingBottom: "16px",
    paddingLeft: "16px",
    flexDirection: "column",
    alignItems: "center",
    alignSelf: "stretch",
    fontWeight: "400",
    fontStyle: "normal",
  },
  listContainer: {
    marginBottom: "8px",
    fontSize: "1rem",
  },
  listItem: {
    marginBottom: "24px",
    display: "flex",
    textAlign: "center",
  },
  button: {
    marginBottom: "24px",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
});
const InitPane = ({ setPane }) => {
  const sharedClasses = useSharedStyles();
  const classes = useStyles();
  const handleClick = () => {
    setPane("auth");
  };
  const MSLoginClick = async () => {
    const response = await MVCRequest("get", "account/getmsauthlink", null, {
      client: "outlook",
      username: "",
      returnUrl: "?outlookLoginTrue",
    });
    if (!response.isAxiosError) displayDialogue(response.data);
  };

  return (
    <div className={sharedClasses.mainContainer}>
      <div className={classes.innerContainer}>
        <span className={sharedClasses.title}>Access CasePacer from Outlook with Ease</span>
        <div className={classes.listContainer}>
          <div className={classes.listItem}>
            <span className={sharedClasses.bodyText}>
              Easily connect Outlook emails associated to your firm’s cases
            </span>
          </div>
        </div>
        <div className={classes.buttonContainer}>
          <button className={`${sharedClasses.button} ${classes.button}`} onClick={handleClick}>
            Connect through CasePacer
          </button>
          <button className={`${sharedClasses.button} ${classes.button}`} onClick={MSLoginClick}>
            Connect through Outlook
          </button>
        </div>
      </div>
    </div>
  );
};

InitPane.propTypes = {
  setPane: PropTypes.func,
};

export default InitPane;
