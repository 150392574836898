/* global window */
import React, { useState, useEffect } from "react";
import InitPane from "./panes/initPane/InitPane";
import AuthPane from "./panes/authPane/AuthPane";
import SearchPane from "./panes/searchPane/SearchPane";
import RelatedPane from "./panes/relatedPane/RelatedPane";
import WarnPane from "./panes/warningPane/WarnPane";
import ChoosePane from "./panes/choosePane/ChoosePane";
import { MVCRequest } from "utility/ApiRequest";
import { sendCodeMessage } from "taskpane/office-document";

const App = () => {
  const [pane, setPane] = useState("init");
  useEffect(() => {
    const checkStatus = async () => {
      const response = await MVCRequest("get", "/Account/Status", null, "");
      if (!response.isAxiosError) {
        if (response.data.partyId !== null) {
          setPane("search");
        } else {
          setPane("init");
        }
        return;
      }
    };
    if (window.location.href.includes("choose=true")) {
      setPane("choose");
    } else if (window.location.href.includes("outlookLoginTrue")) {
      sendCodeMessage();
      window.close();
    } else {
      checkStatus();
    }
  }, []);
  switch (pane) {
    case "init":
      return <InitPane setPane={setPane} />;
    case "auth":
      return <AuthPane setPane={setPane} />;
    case "search":
      return <SearchPane setPane={setPane} />;
    case "authWarn":
      return <WarnPane setPane={setPane} message={"auth"} />;
    case "tokenWarn":
      return <WarnPane setPane={setPane} message={"token"} />;
    case "choose":
      return <ChoosePane />;
    default:
      return <RelatedPane selectedCases={pane} />;
  }
};

export default App;
