import { makeStyles } from "@fluentui/react-components";

const useSharedStyles = makeStyles({
  mainContainer: {
    display: "flex",
    paddingBottom: "16px",
    paddingRight: "16px",
    paddingTop: "16px",
    paddingLeft: "16px",
    flexDirection: "column",
    alignItems: "flex-start",
    flexGrow: "1",
    flexShrink: "0",
    flexBasis: "0",
    alignSelf: "stretch",
    fontFamily: "Segoe UI",
  },
  button: {
    paddingTop: "5px",
    paddingBottom: "5px",
    paddingRight: "12px",
    paddingLeft: "12px",
    backgroundColor: "#0F6CBD",
    cursor: "pointer",
    borderBottomLeftRadius: "4px",
    borderBottomRightRadius: "4px",
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
    borderTopWidth: "0px",
    borderRightWidth: "0px",
    borderBottomWidth: "0px",
    borderLeftWidth: "0px",
    fontSize: "0.875rem",
    fontWeight: "600",
    lineHeight: "1.25rem",
    color: "#FFF",
  },
  boldText: {
    color: "#333",
    fontSize: "0.875rem",
    fontWeight: "700",
    lineHeight: "normal",
  },
  input: {
    paddingRight: "8px",
    paddingLeft: "8px",
    height: "2rem",
    borderBottomLeftRadius: "4px",
    borderBottomRightRadius: "4px",
    borderTopLeftRadius: "4px",
    borderTopRightRadius: "4px",
    borderBottomColor: "#333",
    borderTopColor: "#D1D1D1",
    borderRightColor: "#D1D1D1",
    borderLeftColor: "#D1D1D1",
    borderTopStyle: "solid",
    borderLeftStyle: "solid",
    borderBottomStyle: "solid",
    borderRightStyle: "solid",
    ":focus": {
      borderBottomColor: "#0F6CBD",
      borderTopColor: "#D1D1D1",
      borderLeftColor: "#D1D1D1",
      borderRightColor: "#D1D1D1",
      outlineWidth: "0px",
      outlineStyle: "solid",
    },
  },
  title: {
    alignSelf: "stretch",
    textAlign: "center",
    fontSize: "1.5rem",
    lineHeight: "normal",
    marginTop: "0",
    marginBottom: "32px",
  },
  bodyText: {
    color: "#333",
    fontSize: "1rem",
    lineheight: "normal",
    fontWeight: "400",
  },
  smallBodyText: {
    color: "#333",
    fontSize: "0.875rem",
    fontWeight: "400",
    lineHeight: "normal",
  },
});

export default useSharedStyles;
