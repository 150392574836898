import React from "react";
import { makeStyles } from "@fluentui/react-components";
import useSharedStyles from "utility/Styles";

const useStyles = makeStyles({
  container: {
    paddingLeft: "12px",
    paddingRight: "12px",
    paddingTop: "8px",
    paddingBottom: "8px",
    borderLeftStyle: "solid",
    borderLeftWidth: "4px",
    borderLeftColor: "#000",
    backgroundColor: "#E7E9E5",
    marginTop: "8px",
    marginBottom: "8px",
  },
  text: {
    color: "#333",
  },
});

const UseWarning = () => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles();
  return (
    <div className={classes.container}>
      <span className={sharedClasses.smallBodyText}>
        Note: Only the selected email will be assigned to the selected case(s).
      </span>
    </div>
  );
};

export default UseWarning;
