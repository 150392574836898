import React, { useEffect, useState } from "react";
import { MVCRequest } from "../../utility/ApiRequest";
import { makeStyles } from "@fluentui/react-components";
import UserCard from "./components/UserCard";
import useSharedStyles from "utility/Styles";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    textAlign: "center",
  },
  card: {
    borderRightColor: "black",
    borderTopColor: "black",
    borderBottomColor: "black",
    borderLeftColor: "black",
    borderRightWidth: "1px",
    borderTopWidth: "1px",
    borderBottomWidth: "1px",
    borderLeftWidth: "1px",
    borderTopRightRadius: "4px",
    borderTopLeftRadius: "4px",
    borderBottomRightRadius: "4px",
    borderBottomLeftRadius: "4px",
    borderBottomStyle: "solid",
    borderTopStyle: "solid",
    borderRightStyle: "solid",
    borderLeftStyle: "solid",
    backgroundColor: "white",
    marginTop: "8px",
    paddingTop: "8px",
    paddingBottom: "8px",
    paddingLeft: "4px",
    paddingRight: "4px",
  },
});

const ChoosePane = () => {
  const sharedClasses = useSharedStyles();
  const classes = useStyles();
  const [users, setUsers] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const response = await MVCRequest("get", "/Account/SwitchUser", null, null);
      if (!response.isAxiosError) {
        setUsers(response.data);
      }
    };
    fetchData();
  }, []);
  return (
    <div className={sharedClasses.mainContainer}>
      <div className={classes.container}>
        <span className={sharedClasses.boldText}>Choose an account to sign in with</span>
        <div className={classes.card}>
          {users.map((user) => {
            return <UserCard key={user.partyId} user={user} />;
          })}
        </div>
      </div>
    </div>
  );
};

export default ChoosePane;
