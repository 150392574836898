import React from "react";
import { makeStyles } from "@fluentui/react-components";
const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    width: "100%",
    lineHeight: "normal",
    textAlign: "center",
  },
  topText: {
    marginBottom: "4px",
    fontSize: "0.875rem",
    fontWeight: "700",
    color: "#333",
  },
});

const NoSearch = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <span className={classes.topText}>Search by client name or case number</span>
    </div>
  );
};

export default NoSearch;
