import React, { useState } from "react";
import { MVCRequest } from "utility/ApiRequest";
import PropTypes from "prop-types";
import useSharedStyles from "utility/Styles";
import { makeStyles } from "@fluentui/react-components";

const useStyles = makeStyles({
  inputContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  input: {
    alignSelf: "stretch",
    marginTop: "8px",
  },
  button: {
    width: "100%",
    marginTop: "16px",
  },
  errorContainer: {
    width: "100%",
    marginTop: "5px",
  },
  errorText: {
    width: "100%",
    textAlign: "center",
    display: "inline-block",
    color: "#FF0000",
    fontSize: "0.875rem",
  },
  topText: {
    width: "100%",
    textAlign: "center",
  },
});

const AuthPane = ({ setPane }) => {
  const sharedClasses = useSharedStyles();
  const classes = useStyles();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [didError, setDidError] = useState(false);
  const handleClick = async () => {
    const response = await MVCRequest("post", "/account/login", { username, password }, "");
    if (!response.isAxiosError) {
      setPane("search");
    } else {
      setDidError(true);
    }
  };
  const handleChangeUser = (e) => {
    setUsername(e.target.value);
  };
  const handleChangePass = (e) => {
    setPassword(e.target.value);
  };
  const goBack = () => {
    setPane("init");
  };

  return (
    <div className={sharedClasses.mainContainer}>
      <span className={`${sharedClasses.boldText} ${classes.topText}`}>Login to your account</span>
      <div className={classes.inputContainer}>
        <input
          className={`${sharedClasses.input} ${classes.input}`}
          value={username}
          onChange={handleChangeUser}
        ></input>
        <input
          className={`${sharedClasses.input} ${classes.input}`}
          value={password}
          type="password"
          onChange={handleChangePass}
        ></input>
      </div>
      <div style={{ display: didError ? "block" : "none" }} className={classes.errorContainer}>
        <span className={classes.errorText}>Incorrect username or password!</span>
      </div>
      <button className={`${sharedClasses.button} ${classes.button}`} type="submit" onClick={handleClick}>
        Login
      </button>
      <button className={`${sharedClasses.button} ${classes.button}`} type="submit" onClick={goBack}>
        Go Back
      </button>
    </div>
  );
};

AuthPane.propTypes = {
  setPane: PropTypes.func,
};

export default AuthPane;
