/* global process */
import axios from "axios";

const urlMVCPrefix = process.env.MVC_BASE_URL;
const urlODataPrefix = process.env.ODATA_BASE_URL;

export async function MVCRequest(method, endpoint, postData, query) {
  const response = await axios({
    method,
    baseURL: urlMVCPrefix,
    url: endpoint,
    params: query,
    data: postData,
    responseType: "json",
    withCredentials: true,
  }).catch((error) => {
    return error;
  });
  return response;
}

export async function ODataRequest(method, endpoint, postData, query) {
  const response = await axios({
    method,
    baseURL: urlODataPrefix,
    url: endpoint,
    params: query,
    data: postData,
    responseType: "json",
    withCredentials: true,
  }).catch((error) => {
    return error;
  });
  return response;
}
