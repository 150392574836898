/* global console */
import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { MVCRequest } from "utility/ApiRequest";
import { getEmailId, getEmail } from "taskpane/office-document";
import useSharedStyles from "utility/Styles";
import { makeStyles } from "@fluentui/react-components";
import NoSearch from "./components/NoSearch";
import CaseSection from "./components/CaseSection";
import SearchIcon from "assets/searchIcon.png";
import debounce from "lodash/debounce";
import Identity from "components/Identity";
import UseWarning from "components/UseWarning";

const useStyles = makeStyles({
  searchResults: {
    paddingTop: "16px",
    paddingBottom: "16px",
    width: "100%",
  },
  button: {
    width: "100%",
    marginTop: "8px",
  },
  label: {
    color: "#242424",
    fontSize: "0.875rem",
    fontWeight: "400",
    lineHeight: "normal",
    marginBottom: "5px",
    width: "100%",
  },
  input: {
    paddingLeft: "32px",
  },
  topContainer: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },
  inputContainer: {
    width: "100%",
    position: "relative",
    display: "flex",
    flexDirection: "column",
  },
  icon: {
    position: "absolute",
    left: "8px",
    top: "0",
    bottom: "0",
    marginTop: "auto",
    marginBottom: "auto",
  },
});
const SearchPane = ({ setPane }) => {
  const sharedClasses = useSharedStyles();
  const classes = useStyles();
  const [openCases, setOpenCases] = useState([]);
  const [closedCases, setClosedCases] = useState([]);
  const [potentialCases, setPotentialCases] = useState([]);
  const [selectedCases, setSelectedCases] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    const checkStatus = async () => {
      const responseEmails = await MVCRequest("get", "/OutlookUtility/GetFirmSenders", null, "");
      if (!responseEmails.isAxiosError) {
        if (!(responseEmails.data.filter((e) => e === getEmail()).length > 0)) setPane("authWarn");
      }
      const response = await MVCRequest("get", "/OutlookUtility/checkMSGraph", null, "");
      if (!response.isAxiosError) {
        if (
          response.data === "" ||
          response.data.substring(response.data.indexOf("(") + 1, response.data.indexOf(")")) !== getEmail()
        )
          setPane("tokenWarn");
      }
    };
    checkStatus();
  }, []);

  const handleClick = async () => {
    const postData = {
      cases: selectedCases.map((c) => c.id),
      emailId: getEmailId(),
    };
    const response = await MVCRequest("post", "/OutlookUtility/forwardEmail", postData, "");
    if (!response.isAxiosError) {
      setPane(selectedCases);
    } else {
      console.log("Error!");
    }
  };

  const handleChange = (e) => {
    setSearch(e.target.value);
    debouncedSearch(e);
  };
  const debounceSearch = (searchFunction) => {
    return debounce((params) => searchFunction(params), 100);
  };

  const debouncedSearch = useCallback(
    debounceSearch(async (e) => {
      const response = await MVCRequest("get", "/case/SearchLawsuitList", null, { query: e.target.value });
      if (!response.isAxiosError) {
        let tempOpen = response.data.open;
        let tempClosed = response.data.closed;
        let tempPotential = response.data.potential;
        const selectedIds = Object.fromEntries(selectedCases.map((y) => [y.id, true]));
        tempOpen = tempOpen.filter((y) => !selectedIds[y.id]);
        tempClosed = tempClosed.filter((y) => !selectedIds[y.id]);
        tempPotential = tempPotential.filter((y) => !selectedIds[y.id]);
        setOpenCases(tempOpen);
        setClosedCases(tempClosed);
        setPotentialCases(tempPotential);
      } else {
        console.log("Error!");
      }
    }),
    [selectedCases]
  );

  const handleLogout = async () => {
    const response = await MVCRequest("post", "/account/Logout", null, "");
    if (!response.isAxiosError) {
      setPane("init");
    } else {
      console.log("error logging out!");
    }
  };

  return (
    <div className={sharedClasses.mainContainer}>
      <Identity />
      <UseWarning />
      <div className={classes.topContainer}>
        <span className={classes.label}>Select case(s) related to this email</span>
        <div className={classes.inputContainer}>
          <input className={`${sharedClasses.input} ${classes.input}`} onChange={handleChange} value={search}></input>
          <img src={SearchIcon} alt="Search Icon" className={classes.icon} />
        </div>
      </div>
      <div className={classes.searchResults}>
        {!search && !selectedCases.length && <NoSearch />}
        {!!selectedCases.length && (
          <CaseSection
            key="Selected Cases"
            cases={selectedCases}
            title="Selected Cases"
            setSelectedCases={setSelectedCases}
            setOpenCases={setOpenCases}
            setPotentialCases={setPotentialCases}
            setClosedCases={setClosedCases}
            defaultChecked={true}
          />
        )}
        {search && !!openCases.length && (
          <CaseSection
            key="Open Cases"
            cases={openCases}
            title="Open Cases"
            setSelectedCases={setSelectedCases}
            setOpenCases={setOpenCases}
            setPotentialCases={setPotentialCases}
            setClosedCases={setClosedCases}
            defaultChecked={false}
          />
        )}
        {search && !!potentialCases.length && (
          <CaseSection
            key="Potential Cases"
            cases={potentialCases}
            title="Potential Cases"
            setSelectedCases={setSelectedCases}
            setOpenCases={setOpenCases}
            setPotentialCases={setPotentialCases}
            setClosedCases={setClosedCases}
            defaultChecked={false}
          />
        )}
        {search && !!closedCases.length && (
          <CaseSection
            key="Closed Cases"
            cases={closedCases}
            title="Closed Cases"
            setSelectedCases={setSelectedCases}
            setOpenCases={setOpenCases}
            setPotentialCases={setPotentialCases}
            setClosedCases={setClosedCases}
            defaultChecked={false}
          />
        )}
      </div>
      {!!selectedCases.length && (
        <button className={`${sharedClasses.button} ${classes.button}`} onClick={handleClick}>
          Submit
        </button>
      )}
      <button className={`${sharedClasses.button} ${classes.button}`} onClick={handleLogout}>
        Logout
      </button>
    </div>
  );
};

SearchPane.propTypes = {
  setPane: PropTypes.func,
};

export default SearchPane;
