/* global console */
import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@fluentui/react-components";
import useSharedStyles from "utility/Styles";
import { MVCRequest } from "utility/ApiRequest";

const useStyles = makeStyles({
  button: {
    width: "100%",
    marginTop: "16px",
  },
  header: {
    width: "100%",
    textAlign: "center",
    marginTop: "8px",
    marginBottom: "16px",
  },
  message: {
    width: "100%",
    textAlign: "center",
    marginBottom: "1.5rem",
  },
  messageContainer: {
    display: "flex",
    flexDirection: "column",
  },
  listContainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: "1rem",
    marginBottom: "1rem",
  },
  counter: {
    marginRight: "4px",
    color: "#000",
    lineHeight: "1rem",
  },
  action: {
    marginBottom: "0.5rem",
    "&:last-child": {
      marginBottom: "0",
    },
  },
});

const WarnPane = ({ setPane, message }) => {
  const classes = useStyles();
  const sharedClasses = useSharedStyles();
  const handleClick = async () => {
    const response = await MVCRequest("post", "/account/Logout", null, "");
    if (!response.isAxiosError) {
      setPane("init");
    } else {
      console.log("error logging out!");
    }
  };

  return (
    <div className={sharedClasses.mainContainer}>
      <span className={sharedClasses.title}>Warning!</span>
      {message === "auth" && (
        <span className={`${sharedClasses.bodyText} ${classes.message}`}>
          This email is not listed as an authorized sender for this account&apos;s firm. Please contact a firm
          administrator to add your email to this list.
        </span>
      )}
      {message === "token" && (
        <div className={`${sharedClasses.bodyText} ${classes.message}`}>
          <span className={`${sharedClasses.bodyText} ${classes.message}`}>
            This CasePacer account does not have an Outlook integration setup with this email. Follow these steps to
            resolve this:
          </span>
          <div className={classes.listContainer}>
            <span className={classes.action}>
              <span className={classes.counter}>①</span>
              Login to CasePacer at{" "}
              <a href="https://secure.casepacer.com" target="_blank" rel="noreferrer">
                https://secure.casepacer.com
              </a>
            </span>
            <span className={classes.action}>
              <span className={classes.counter}>②</span>
              Navigate to &quot;Settings&quot; / &quot;Calendar Settings&quot;
            </span>
            <span className={classes.action}>
              <span className={classes.counter}>③</span>
              Enable the Microsoft Outlook integration
            </span>
          </div>
          <span className={`${sharedClasses.bodyText} ${classes.message}`}>
            Still having trouble? Make sure your CasePacer user profile matches the email account you are attempting to
            access (&quot;Settings&quot; / &quot;User Settings&quot;)
          </span>
        </div>
      )}
      <button onClick={handleClick} className={`${sharedClasses.button} ${classes.button}`}>
        Logout
      </button>
    </div>
  );
};

WarnPane.propTypes = {
  message: PropTypes.string,
  setPane: PropTypes.func,
};

export default WarnPane;
