/* global window */
import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@fluentui/react-components";
import { MVCRequest } from "utility/ApiRequest";
import { sendCodeMessage } from "taskpane/office-document";
import useSharedStyles from "utility/Styles";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    borderTopRightRadius: "4px",
    borderTopLeftRadius: "4px",
    borderBottomLeftRadius: "4px",
    borderBottomRightRadius: "4px",
    paddingTop: "4px",
    paddingBottom: "4px",
    paddingRight: "4px",
    paddingLeft: "4px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#D1D1D1",
    },
  },
});

const UserCard = ({ user }) => {
  const sharedClasses = useSharedStyles();
  const classes = useStyles();

  const handleClick = async () => {
    const response = await MVCRequest("post", "/Account/SwitchUser", null, {
      id: user.partyId,
      userName: user.userName,
    });
    if (!response.isAxiosError) {
      sendCodeMessage();
      window.close();
    }
  };

  return (
    <div onClick={handleClick} className={classes.container}>
      <span className={sharedClasses.bodyText}>
        <span className={sharedClasses.boldText}>{user.userName}</span> ({user.displayName})
      </span>
      <span className={sharedClasses.bodyText}>
        {user.firmName} • {user.casePacerUserTypeName}
      </span>
    </div>
  );
};

export default UserCard;

UserCard.propTypes = {
  user: PropTypes.object,
};
