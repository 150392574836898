/* global console */
import React, { useEffect, useState } from "react";
import { MVCRequest } from "utility/ApiRequest";
import { makeStyles, Avatar } from "@fluentui/react-components";

const useStyles = makeStyles({
  container: {
    display: "flex",
    paddingBottom: "8px",
    width: "100%",
    borderBottomWidth: "1px",
    borderBottomColor: "rgba(0, 0, 0, 0.1)",
    borderBottomStyle: "solid",
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "8px",
    lineHeight: "normal",
  },
  avatar: {
    height: "24px",
    width: "24px",
    fontSize: "10px",
  },
  topText: {
    fontSize: "0.75rem",
    color: "#000",
    fontWeight: "500",
  },
  bottomText: {
    fontSize: "0.625rem",
    fontWeight: "400",
    color: "rgba(0, 0, 0, 0.5)",
  },
});

const Identity = () => {
  const [userInfo, setUserInfo] = useState({ firstName: "", lastName: "", role: "", firmName: "", imageURL: "" });
  const classes = useStyles();
  useEffect(() => {
    const fetchData = async () => {
      const response = await MVCRequest("get", "/account/status", null, "");
      if (!response.isAxiosError) {
        const displayName = response.data.displayName;
        setUserInfo({
          firstName: displayName.substring(displayName.lastIndexOf(", ") + 1),
          lastName: displayName.substring(0, displayName.indexOf(", ")),
          role: response.data.casePacerUserTypeName,
          firmName: response.data.firmName,
          imageURL: response.data.imageURL,
        });
      } else {
        console.log("Error");
      }
    };
    fetchData();
  }, []);

  return (
    <div className={classes.container}>
      <Avatar
        className={classes.avatar}
        image={{ src: userInfo.imageURL }}
        name={userInfo.firstName + " " + userInfo.lastName}
      />
      <div className={classes.textContainer}>
        <span className={classes.topText}>
          {userInfo.firstName} {userInfo.lastName}
        </span>
        <span className={classes.bottomText}>
          {userInfo.role} at {userInfo.firmName}
        </span>
      </div>
    </div>
  );
};

export default Identity;
