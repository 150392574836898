import React from "react";
import PropTypes from "prop-types";
import CaseItem from "./components/CaseItem";
import useSharedStyles from "taskpane/utility/Styles";
import Identity from "components/Identity";
import UseWarning from "components/UseWarning";

const RelatedPane = ({ selectedCases }) => {
  const sharedClasses = useSharedStyles();

  return (
    <div className={sharedClasses.mainContainer}>
      <Identity />
      <UseWarning />
      <span className={sharedClasses.boldText}>This email is related to the following case(s)</span>
      {selectedCases.map((item) => {
        return <CaseItem key={item.id} name={item.name} />;
      })}
    </div>
  );
};

RelatedPane.propTypes = {
  selectedCases: PropTypes.array,
};

export default RelatedPane;
