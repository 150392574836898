import React from "react";
import PropTypes from "prop-types";
import CaseItem from "./CaseItem";
import { makeStyles } from "@fluentui/react-components";
import useSharedStyles from "taskpane/utility/Styles";

const useStyles = makeStyles({
  container: {
    display: "flex",
    flexDirection: "column",
    ":first-of-type": {
      marginTop: "10px",
    },
  },
  results: {
    marginTop: "10px",
  },
});

const CaseSection = ({ cases, title, ...passthrough }) => {
  const sharedClasses = useSharedStyles();
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <span className={sharedClasses.boldText}>{title}</span>
      <div className={classes.results}>
        {cases.map((item) => {
          return <CaseItem key={item.id} item={item} {...passthrough} />;
        })}
      </div>
    </div>
  );
};
CaseSection.propTypes = {
  cases: PropTypes.array,
  setSelectedCases: PropTypes.func,
  title: PropTypes.string,
  setOpenCases: PropTypes.func,
  setPotentialCases: PropTypes.func,
  setClosedCases: PropTypes.func,
  defaultChecked: PropTypes.bool,
  firstSection: PropTypes.bool,
};
export default CaseSection;
