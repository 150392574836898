import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@fluentui/react-components";

const useStyles = makeStyles({
  container: {
    display: "flex",
  },
  input: {
    marginLeft: "8px",
    marginRight: "8px",
    marginBottom: "8px",
    marginTop: "8px",
    height: "16px",
    width: "16px",
    borderBottomLeftRadius: "2px",
    borderBottomRightRadius: "2px",
    borderTopLeftRadius: "2px",
    borderTopRightRadius: "2px",
    borderTopColor: "#616161",
    borderRightColor: "#616161",
    borderBottomColor: "#616161",
    borderLeftColor: "#616161",
    borderTopStyle: "solid",
    borderRightStyle: "solid",
    borderBottomStyle: "solid",
    borderLeftStyle: "solid",
    borderTopWidth: "1px",
    borderRightWidth: "1px",
    borderBottomWidth: "1px",
    borderLeftWidth: "1px",
    ":checked": {
      backgroundColor: "#0F6CBD",
      borderTopColor: "#0F6CBD",
      borderRightColor: "#0F6CBD",
      borderBottomColor: "#0F6CBD",
      borderLeftColor: "#0f6CBD",
    },
  },
  text: {
    color: "#333",
    fontSize: "0.875rem",
    fontWeight: "400",
    lineHeight: "normal",
  },
  textContainer: {
    paddingLeft: "4px",
    paddingTop: "5px",
    paddingBottom: "8px",
    paddingRight: "4px",
  },
});

const CaseItem = ({ item, setSelectedCases, defaultChecked, setOpenCases, setPotentialCases, setClosedCases }) => {
  const classes = useStyles();

  let setCurCaseList;
  if (defaultChecked) {
    setCurCaseList = setSelectedCases;
  } else {
    if (item.p === 1) setCurCaseList = setPotentialCases;
    else if (item.p === 7) setCurCaseList = setClosedCases;
    else setCurCaseList = setOpenCases;
  }

  const handleCheck = (e) => {
    const wasChecked = e.target.checked;
    setCurCaseList((prevCaseList) => prevCaseList.filter((x) => x.id !== item.id));
    if (wasChecked) setSelectedCases((prevSelected) => [item, ...prevSelected]);
    else {
      switch (item.p) {
        case 1:
          setPotentialCases((prevPotential) => [...prevPotential, item]);
          break;
        case 2:
          setOpenCases((prevOpen) => [...prevOpen, item]);
          break;
        case 7:
          setClosedCases((prevClosed) => [...prevClosed, item]);
          break;
      }
    }
  };

  const Input = () => {
    if (defaultChecked)
      return <input className={classes.input} onChange={handleCheck} type="checkbox" defaultChecked />;
    else return <input className={classes.input} onChange={handleCheck} type="checkbox" />;
  };

  return (
    <div className={classes.container}>
      <Input />
      <div className={classes.textContainer}>
        <span className={classes.text}>{item.name}</span>
      </div>
    </div>
  );
};

CaseItem.propTypes = {
  item: PropTypes.object,
  setSelectedCases: PropTypes.func,
  defaultChecked: PropTypes.bool,
  setOpenCases: PropTypes.func,
  setPotentialCases: PropTypes.func,
  setClosedCases: PropTypes.func,
};

export default CaseItem;
