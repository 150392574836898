import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@fluentui/react-components";
import checkMark from "assets/checkmark.png";

const useStyles = makeStyles({
  check: {
    marginLeft: "3px",
    marginTop: "3px",
    marginRight: "3px",
    marginBottom: "4px",
  },
  checkboxDiv: {
    borderTopWidth: "1px",
    borderBottomWidth: "1px",
    borderRightWidth: "1px",
    borderLeftWidth: "1px",
    borderTopColor: "#E0E0E0",
    borderBottomColor: "#E0E0E0",
    borderRightColor: "#E0E0E0",
    borderLeftColor: "#E0E0E0",
    borderBottomLeftRadius: "2px",
    borderBottomRightRadius: "2px",
    borderTopLeftRadius: "2px",
    borderTopRightRadius: "2px",
    borderBottomStyle: "solid",
    borderTopStyle: "solid",
    borderLeftStyle: "solid",
    borderRightStyle: "solid",
    backgroundColor: "#FFF",
    height: "16px",
    width: "16px",
    marginRight: "8px",
    marginBottom: "8px",
    marginTop: "8px",
    marginLeft: "8px",
  },
  text: {
    color: "#825FE8",
    fontSize: "0.875rem",
    fontWeight: "600",
    lineHeight: "normal",
  },
  container: {
    display: "flex",
    paddingTop: "8px",
    paddingBottom: "8px",
    borderBottomWidth: "1px",
    borderBottomColor: "#DDD",
    borderBottomStyle: "solid",
    width: "100%",
  },
  textContainer: {
    marginTop: "5px",
    marginBottom: "8px",
    marginRight: "4px",
    marginLeft: "4px",
  },
});

const CaseItem = ({ name }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <div className={classes.checkboxDiv}>
        <img src={checkMark} className={classes.check} />
      </div>
      <div className={classes.textContainer}>
        <span className={classes.text}>{name}</span>
      </div>
    </div>
  );
};
CaseItem.propTypes = {
  name: PropTypes.string,
};
export default CaseItem;
